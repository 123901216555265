<template>
<v-dialog v-model="show" max-width="500px">
  <v-card>
    <v-card-title>
        Clear Conirmation
    </v-card-title>
    <v-card-text>
        سلاو، دلينياي لەم كاره
    </v-card-text>
    <v-card-actions>
      <!-- <v-btn color="error"  @click.stop="show=false">Yes</v-btn> -->
      <v-btn color="primary" @click.stop="show=false">No</v-btn>
      <v-btn color="primary" @click="clickme('yes')">Yes</v-btn>
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
export default {
  props: {
     value: Boolean
  },
  computed: {
    show: {
      get () {
        return this.value
      },
      set (value) {
         this.$emit('input', value)
      }
    }
  },
  methods: {
    clickme(value) {
       // console.log('fgfg');
       
        this.$emit('yesOrNo', value)
        this.show=false
    }
  }
  
}
</script>