<template>
  <v-app>
    <v-app-bar
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <router-link to="/">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://deliveryfast.net/images/fast.png"
          transition="scale-transition"
          width="40"
        />
        </router-link>
        Delivery Fast 
        
        <!-- <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        /> -->
      </div>
   
      <v-spacer></v-spacer>
      <v-menu offset-y  v-if="isitAdmin" >
      <template v-slot:activator="{ on, attrs }">
        <v-btn style="margin-left: 10px"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          دوكاندار
        </v-btn>
      </template>
    <v-list dense>
      <!-- <v-subheader>REPORTS</v-subheader> -->
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
      >
        <v-list-item 
          v-for="(item, i) in items"
          :key="i"
          :to="item.link" link
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"  to="item.link" >
              <!-- <router-link v-show="isitAdmin" to="/all-drivers" class="ml-4" style="color: white">Drivers</router-link> -->

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    
    </v-menu>
    <v-menu offset-y  v-if="isitAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn style="margin-left: 10px"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          شوفير
        </v-btn>
      </template>
    <v-list dense>
      <!-- <v-subheader>REPORTS</v-subheader> -->
      <v-list-item-group
        v-model="selectedItem2"
        color="primary"
      >
        <v-list-item 
          v-for="(item, i) in driverItems"
          :key="i"
          :to="item.link" link
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"  to="item.link" >
              <!-- <router-link v-show="isitAdmin" to="/all-drivers" class="ml-4" style="color: white">Drivers</router-link> -->

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    
    </v-menu>

  <v-menu offset-y  v-if="isitAdmin">
      <template v-slot:activator="{ on, attrs }">
        <v-btn style="margin-left: 10px"
          color="primary"
          dark
          v-bind="attrs"
          v-on="on"
        >
          شار
        </v-btn>
      </template>
    <v-list dense>
      <!-- <v-subheader>REPORTS</v-subheader> -->
      <v-list-item-group
        v-model="selectedItem2"
        color="primary"
      >
        <v-list-item 
          v-for="(item, i) in cityItems"
          :key="i"
          :to="item.link" link
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="item.text"  to="item.link" >
              <!-- <router-link v-show="isitAdmin" to="/all-drivers" class="ml-4" style="color: white">Drivers</router-link> -->

            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    
    </v-menu>

    <!-- <router-link to="/about" style="color: white">دەربارە</router-link> -->
    <!-- <router-link v-show="isitAdmin" to="/all-drivers" class="ml-4" style="color: white">Drivers</router-link>
    <router-link v-show="isitAdmin" to="/all-dukandar" class="ml-4" style="color: white">dukandar</router-link> -->
      <router-link to="/search" style="color: white">گەران</router-link>
      <router-link v-if="isistDukandar" to="/dukandar-invoices" style="color: white; padding-right: 10px">وصل</router-link>
 <v-spacer></v-spacer>
  <v-spacer></v-spacer>
      <v-btn
        text
        @click="logoutMe"
      >
        <span class="mr-2">Log out</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
           <v-btn
        text
        @click="logoutMe"
      >
        <span class="mr-2">{{firstName}}</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>
import {mapActions, mapState} from 'vuex'
export default {
  name: 'App',

  data: () => ({
    //
     selectedItem: 1,
     selectedItem2: 1,
      items: [
         { text: 'دروستكردني دوكاندار', icon: 'mdi-clock', link: '/new-dukandar' },
        { text: 'ليستي دوكاندار', icon: 'mdi-clock', link: '/dukandar-list-names' },
        { text: 'قائمە بۆ دوكاندار', icon: 'mdi-account', link: '/dukandar-list' },
        { text: 'پاكانە', icon: 'mdi-flag', link: '/all-dukandar' },
      ],
      driverItems: [
         { text: 'دروستكردني شوفير', icon: 'mdi-clock', link: '/new-driver' },
        { text: 'ليستي شوفير', icon: 'mdi-clock', link: '/driver-list-names' },
        { text: 'قائمە بۆ شوفير', icon: 'mdi-account', link: '/driver-list' },
        { text: 'پاكانە', icon: 'mdi-flag', link: '/all-drivers' },
      ],
      cityItems: [
         { text: 'دروستكردني شار', icon: 'mdi-clock', link: '/new-city' },
        { text: 'ليستي شار', icon: 'mdi-clock', link: '/cities-list-names' },
      ]
  }),
  methods: {
    ...mapActions(['logout', 'refresh', 'loadPosts']),
    logoutMe() {
      this.logout()
    },
    
  },
  computed : {
    ...mapState(['userType', 'firstName']),
    isitAdmin() {
      return this.userType == 'admin'
    },
    isistDukandar() {
      return this.userType =='dukandar'
    }
  },
  created() {
      this.refresh()
      this.loadPosts()
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Amiri:wght@700&display=swap');

html, body {
  font-family: 'Amiri', serif;
}

#app {
  font-family: 'Amiri', serif;
}
</style>

