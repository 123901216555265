<template>
<div>
        <!-- <v-autocomplete
         v-model="value"
        :items="DukanItems"
        item-text="dukandarName"
        item-value="id"
        clearable
        filled
        rounded
        solo
        
></v-autocomplete>
<v-btn
  class="ma-2"
      outlined
      color="indigo"
  @click="updateDukandarIdClick"
>Load</v-btn> -->
<v-card flat>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-switch
              v-model="hideDukandar"
              label="دوکاندار"
              color="red"
              value="red"
              hide-details
            ></v-switch>
            <v-switch
              v-model="hideRecivingDate"
              label="رێکەوتی وەرگرتن"
              color="red darken-3"
              value="red darken-3"
              hide-details
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-switch
              v-model="hideDriver"
              label="شۆفێر"
              color="indigo"
              value="indigo"
              hide-details
            ></v-switch>
            <v-switch
              v-model="hideDriverMobile"
              label="مۆبایلی شۆفیر"
              color="indigo darken-3"
              value="indigo darken-3"
              hide-details
            ></v-switch>
          </v-col>
          <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-switch
              v-model="ex11"
              label="orange"
              color="orange"
              value="orange"
              hide-details
            ></v-switch>
            <v-switch
              v-model="ex11"
              label="orange darken-3"
              color="orange darken-3"
              value="orange darken-3"
              hide-details
            ></v-switch>
          </v-col>
           <v-col
            cols="12"
            sm="3"
            md="3"
          >
            <v-switch
              v-model="ex11"
              label="orange"
              color="orange"
              value="orange"
              hide-details
            ></v-switch>
            <v-switch
              v-model="ex11"
              label="orange darken-3"
              color="orange darken-3"
              value="orange darken-3"
              hide-details
            ></v-switch>
          </v-col>
        </v-row>

      </v-container>
    </v-card-text>
  </v-card>
  <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
 <v-data-table
    :headers="headers"
    :items="ordersList"
    :items-per-page="5"
    class="elevation-1"
    :item-class="itemRowBackground"
    :search="search"
    mobile-breakpoint="0"
    @click:row="handleClick"

  >
      
   <template v-slot:[`item.receivingDate`]="{ item }">
                   <span>{{ item.receivingDate | date}}</span>
               

  </template>
   <template v-slot:[`item.status`]="{ item }">
                 <v-chip  :color="getColor(item.status)"  dark>
                      {{ getStatus(item.status) }}
                  </v-chip>

  </template>

   <template slot="body.append">
      

                    <tr class="pink--text">
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title"></th>
                        <th class="title">{{ sumAmount() | currency2 }}</th>
                        <th class="title">{{ sumCommission() | currency2 }}</th>
                        <th class="title">{{ sumAmount() + sumCommission() | currency2 }}</th>
                     
                    </tr>
  </template>
  <template v-slot:[`item.amount`]="{ item }">
    <span>{{ item.amount | currency}}</span>
  </template>

   <template v-slot:[`item.actions`]="{ item }">

        <v-icon small  @click="deleteItem(item)" >  mdi-delete    </v-icon>
       
        </template>
  </v-data-table>
</div>
 
</template>

<script>
import { mapState, mapActions } from "vuex"

  export default {
    data () {
      return {
     
        desserts: [
          {
            name: 'Frozen Yogurt',
            calories: 159,
            fat: 6.0,
            carbs: 24,
            protein: 4.0,
            iron: '1%',
          },
          {
            name: 'Ice cream sandwich',
            calories: 237,
            fat: 9.0,
            carbs: 37,
            protein: 4.3,
            iron: '1%',
          },
          {
            name: 'Eclair',
            calories: 262,
            fat: 16.0,
            carbs: 23,
            protein: 6.0,
            iron: '7%',
          },
          {
            name: 'Cupcake',
            calories: 305,
            fat: 3.7,
            carbs: 67,
            protein: 4.3,
            iron: '8%',
          },
          {
            name: 'Gingerbread',
            calories: 356,
            fat: 16.0,
            carbs: 49,
            protein: 3.9,
            iron: '16%',
          },
          {
            name: 'Jelly bean',
            calories: 375,
            fat: 0.0,
            carbs: 94,
            protein: 0.0,
            iron: '0%',
          },
          {
            name: 'Lollipop',
            calories: 392,
            fat: 0.2,
            carbs: 98,
            protein: 0,
            iron: '2%',
          },
          {
            name: 'Honeycomb',
            calories: 408,
            fat: 3.2,
            carbs: 87,
            protein: 6.5,
            iron: '45%',
          },
          {
            name: 'Donut',
            calories: 452,
            fat: 25.0,
            carbs: 51,
            protein: 4.9,
            iron: '22%',
          },
          {
            name: 'KitKat',
            calories: 518,
            fat: 26.0,
            carbs: 65,
            protein: 7,
            iron: '6%',
          },
        ],
      items: Object,
      values: Object,
      value: null,
      search: '',
      hideDukandar: false,
      hideRecivingDate: false,
      hideDriverMobile: false,
      hideDriver: false
      }
    },
      props: {
      ordersList: [],
    },
    computed: {
      ...mapState(['filteredOrderList', 'userType'])//(['dukandarList', 'filteredOrderList'])
      ,
      // DukanItems () {
      //     return  this.dukandarList.map(x=> ({id: x.id, dukandarName: x.dukandarName}))
      // },
      DukanValues () {
          return ['foo', 'bar']
      },
      headers() {
        let  headers= [
           { text: 'id', value: 'id' },
          {
            text: 'ناوي كريار',
            align: 'start',
            sortable: false,
            value: 'customerName',
          },
          { text: 'شار', value: 'city' },
          
          { text: 'موبايلي كريار', value: 'mobile' },
         
          
          { text: 'پارە', value: 'amount' },
          { text: 'كري', value: 'commission' },
          { text: 'ناونيشان', value: 'address' },
          
          
          {text: 'بارودۆخ' , value: 'status'}
          // { text: 'شۆفير', value: 'driverName' },
          // { text: 'موبايلي', value: 'driverMobile' },
          
        ]
         if (!this.hideDukandar) {
          headers.push({ text: 'دوكاندار', value: 'dukandar' })
         }
          if (!this.hideRecivingDate) {
          headers.push({ text: 'بەرواري وەرگرتن', value: 'receivingDate' })
          }
         if (!this.hideDriver) {
          headers.push( { text: 'شۆفێر', value: 'driverName' },)
         }
          if (!this.hideDriverMobile) {
          headers.push({ text: 'موبایلی', value: 'driverMobile' },)
          }
          return headers
      }
     
    },
methods: {
        ...mapActions(['loadPosts', 'setdukandaeNameAndStatusForAdmin']),
        updateDukandarIdClick() {
        //console.log(this.value);
        this.setdukandaeNameAndStatusForAdmin(this.value)
         this.$store.dispatch('loadPosts');
        },
      itemRowBackground: function (item) {
        return item.id > 100 ? 'style-1' : 'style-2'
      },
      deleteItem() {

      },
      sumAmount() {
        return this.ordersList.reduce((acc, item) => acc + item.amount, 0)
      },
      handleClick(value) {
        if (this.userType == 'admin')
             this.$router.push({ name: 'Transport', params: { id: value.id}})
           // console.log(value);
        },
      sumCommission() {
        return this.ordersList.reduce((acc, item) => acc + item.commission, 0)
      },
      getColor (calories) {
        if (calories <=3) return 'blue'
        else if (calories == 3) return 'green'
        else if (calories == 4) return 'red'
        else return 'orange'
      },
      getStatus (calories) {
        if (calories == 0) return 'نوی'
        else if (calories == 1) return 'لای شۆفیر'
        else if (calories == 2) return 'گەیشت'
        else if (calories == 3) return 'تەسلیم کرا'
        else if (calories == 4) return 'گەراوە'
        else if (calories == 5) return 'چاوەرانە'
        else if (calories == 6) return 'ئامدەیە'
        else return 'نازانم'
      },
    },
    created() {
      //this.loadDDukandars()
    },
    filters: {
      currency: function (value) {
        if (typeof value !== "number") {
        return value;
        }
        var formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'IQD'
        });
       return formatter.format(value);
      },
      currency2: function (value) {
        if (typeof value !== "number") {
        return value;
        }
      
       return Number((value).toFixed(0)).toLocaleString()

      },
       date: function(theDate) {
       return  theDate==null? '': new Date(theDate).toLocaleDateString("en-US")
     }
    }
  }
</script>

<style scoped>
.style-1 {
  background-color: rgb(215,215,44)
}
.style-2 {
  background-color: rgb(26, 101, 170)
}
</style>