<template>
 <v-container class="grey lighten-5">
  <!-- <vue-record-audio @result="onResult" />
  <audio :src="path" controls="controls">  </audio> -->
  <login v-if="!isLoged" />
  <orders v-show="isItDriver || isItDukandar" />
  
 </v-container>
</template>

<script>
  import Login from '../components/Login'
 
 

  import {mapState, mapActions} from 'vuex'
  import Orders from '../components/Orders.vue'
  export default {
    name: 'Home',
    data () {
      return {
         selectedStatus: '',
         selection: 0,
         path:""
      }
    
    },
    methods: {
    ...mapActions(['resetDukandaeNameAndStatusForAdmin', 'updateVoiceNote']),
     onResult (data) {
      console.log('The blob data:', data);
      console.log('Downloadable audio', window.URL.createObjectURL(data));
      this.path= window.URL.createObjectURL(data)
      this.updateVoiceNote({'id': 555, 'voiceNote': data})
    }
    },
    computed: {
       ...mapState(['token', 'userId', 'userType']),
      
      isLoged () {
        return !!this.token
      },
      isItAdmin() { 
        return this.userType == "admin"
      },
      isItDriver() {
          return this.userType== 'driver'
      },
      isItDukandar() {
         return this.userType== 'dukandar'
      }
        
    },
    components: {
      Login,
        Orders,

    },
  
     created() {
    this.resetDukandaeNameAndStatusForAdmin()
    this.$store.dispatch('loadPosts');
  },
  }
</script>
