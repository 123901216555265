<template>
  <v-form
    ref="form"
    v-model="valid"
    lazy-validation
  >
    <v-text-field
      v-model="name"
      :counter="10"
      :rules="nameRules"
      label="Name"
      required
    ></v-text-field>
    <v-text-field
      v-model="password"
      :counter="10"
      :rules="nameRules"
      label="Password"
      required
    ></v-text-field>

    <!-- <v-text-field
      v-model="email"
      :rules="emailRules"
      label="E-mail"
      required
    ></v-text-field> -->

    <!-- <v-select
      v-model="select"
      :items="items"
      :rules="[v => !!v || 'Item is required']"
      label="Item"
      required
    ></v-select> -->

    <!-- <v-checkbox
      v-model="checkbox"
      :rules="[v => !!v || 'You must agree to continue!']"
      label="Do you agree?"
      required
    ></v-checkbox> -->
<v-btn
     
      color="success"
      class="mr-4"
      @click="loginUser"
    >
    login
    </v-btn>
     <v-snackbar
      v-model="snackbar"
      
    >
      {{ text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- <v-btn
      :disabled="!valid"
      color="success"
      class="mr-4"
      @click="validate"
    >
      Validate
    </v-btn> -->

    <!-- <v-btn
      color="error"
      class="mr-4"
      @click="reset"
    >
      Reset Form
    </v-btn>

    <v-btn
      color="warning"
      @click="resetValidation"
    >
      Reset Validation
    </v-btn> -->
  </v-form>
</template>

<script>
import {mapActions, mapState} from 'vuex'
  export default {
    data: () => ({
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length <= 10) || 'Name must be less than 10 characters',
      ],
      email: '',
      password:'',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      select: null,
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      checkbox: false,
      snackbar: false,
      text:''
    }),

    methods: {
      ...mapActions(['login', 'loadPosts']),
      validate () {
        this.$refs.form.validate()
      },
      reset () {
        this.$refs.form.reset()
      },
      resetValidation () {
        this.$refs.form.resetValidation()
      },
      loginUser() {
        //console.log('fdfdfdf');
        this.login({'name': this.name, 'password': this.password})
        // if (this.userId != 0) {
        if (this.loginStatus) {
          this.text= 'Login Seccsseed'
          this.snackbar= true
        }  
        else {
          this.text= 'User of password Error'
          this.snackbar= true
        }
           
        //   console.log(this.userId);
        //   this.loadPosts()
        // }
      }
    },
    computed : {
      ...mapState(['token', 'userId', 'loginStatus'])
    }
  }
</script>