import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/dukandar-list',
    name: 'DukanDarList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/DukanDarList.vue')
  },
  {
    path: '/driver-list',
    name: 'DriverList',
    component: () => import(/* webpackChunkName: "about" */ '../views/DriverList.vue')
  },
  {
    path: '/driver-list-names',
    name: 'Drivers',
    component: () => import(/* webpackChunkName: "about" */ '../views/Drivers.vue')
  },
  {
    path: '/cities-list-names',
    name: 'Cities',
    component: () => import(/* webpackChunkName: "about" */ '../views/Cities.vue')
  },
  {
    path: '/new-dukandar',
    name: 'NewDukandar',
    component: () => import(/* webpackChunkName: "about" */ '../components/NewDukandar.vue')
  },
  {
    path: '/dukandar-invoices',
    name: 'Invoices',
    component: () => import(/* webpackChunkName: "about" */ '../views/Invoices.vue')
  },
  {
    path: '/new-city',
    name: 'NewCity',
    component: () => import(/* webpackChunkName: "about" */ '../components/NewCity.vue')
  },
  {
    path: '/new-driver',
    name: 'NewDriver',
    component: () => import(/* webpackChunkName: "about" */ '../components/NewDriver.vue')
  },
  {
    path: '/dukandar-list-names',
    name: 'Dukandars',
    component: () => import(/* webpackChunkName: "about" */ '../views/Dukandars.vue')
  },
  {
    path: '/search',
    name: 'Search',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/SearchView.vue')
  },
  {
    path: '/all-drivers',
    name: 'AllDrivers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AllDrivers.vue')
  },
  {
    path: '/all-dukandar',
    name: 'AllDukandar',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AllDukandar.vue')
  },
  {
    path: '/transport/:id',
    name: 'Transport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Transport.vue')
  },
  {
    path: '/dukandar-kashf/:id',
    name: 'DukandarKashf',
   
    component: () => import(/* webpackChunkName: "about" */ '../views/DukandarKashf.vue')
  },
  // {
  //   path: '/login',
  //   name: 'Login',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
