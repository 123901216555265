<template>
<div>
  <div class="noprint">
    <v-container class="grey lighten-5 mb-2">
       <v-card flat>
    <v-card-text>
      <v-container fluid>
        <v-row>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-switch 
            v-if="isItAdminOrDukandar"
             label="پیشاندانی خشتە"
              color="indigo"
              value="indigo"
              hide-details  v-model="showTable " ></v-switch>
           
          </v-col>
          <v-col
            cols="12"
            sm="4"
            md="4"
          >
          
            <v-switch  label="پێشاندانی رێکەوت"
              color="indigo"
              value="indigo"
              hide-details  v-if="isItAdminOrDrivr" v-model="showaysButtons"></v-switch>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>


       
        <v-row   no-gutters style="height: 150px;">
          <v-col  md="4" lg="3" sm="6" xs="12">
            <v-card class="mx-auto ml-2 mb-2"   max-width="500" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  جديد     </div>
                    <v-list-item-title class="text-h5 mb-1">  {{newCounts}}    </v-list-item-title>
                    <v-list-item-subtitle>بابەتي نوي </v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/1.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(0)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
           <v-col md="4" lg="3" sm="6" xs="12">
            <v-card class="mx-auto ml-2 mb-2"   max-width="344" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  تم التسليم     </div>
                    <v-list-item-title class="text-h5 mb-1">  {{paidCounts}}    </v-list-item-title>
                    <v-list-item-subtitle> لەكن سايق</v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/4.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(3)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
          <v-col md="4" lg="3" sm="6" xs="12">
            <v-card class="mx-auto ml-2 mb-2"   max-width="344" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  راجع كلي     </div>
                    <v-list-item-title class="text-h5 mb-1">  {{rejectCounts}}  </v-list-item-title>
                    <v-list-item-subtitle>گەرانەوە</v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/2.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(4)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
          <v-col md="4" lg="3" sm="6" xs="12" v-show=" isItAdminOrDukandar">
            <v-card class="mx-auto ml-2 mb-2"   max-width="344" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  راجع حاضر     </div>
                    <v-list-item-title class="text-h5 mb-1">  {{rejectAndReadyCounts}}  </v-list-item-title>
                    <v-list-item-subtitle>گەرانەوە</v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/2.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(44)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
           <v-col md="4" lg="3" sm="6" xs="12">
            <v-card class="mx-auto ml-2 "   max-width="344" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  مؤجل     </div>
                    <v-list-item-title class="text-h5 mb-1">  {{pendingCounts}}    </v-list-item-title>
                    <v-list-item-subtitle>بابەتي مؤجل</v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/3.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(5)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
          <v-col v-if="showReadyAmountsforDukandarAndAdmin" md="4" lg="3" sm="6" xs="12">
            <v-card class="mx-auto"   max-width="344" outlined style="background-color:blanchedalmond">
               <v-list-item three-line>
                  <v-list-item-content>
                    <div class=" mb-4">  ئامەدەيە        </div>
                    <v-list-item-title class="text-h5 mb-1">  {{readyCounts}}    </v-list-item-title>
                    <v-list-item-subtitle>  لەكن فاست دليڤەري </v-list-item-subtitle>
                  </v-list-item-content>

                <v-list-item-avatar tile  size="80"   >
                  <v-img style="border-radius: 5px" src="/5.jpg"></v-img>
                 </v-list-item-avatar>
               </v-list-item>

                 <v-card-actions style="flex-flow: row-reverse;">
                    <v-btn style="float:right" outlined rounded  text  @click="selectMe(6)" > ووردکاری  </v-btn>
                </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
    </v-container>


  </div>
  
  

 <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
 <v-row>
     
      <div v-show="showaysButtons">
        <v-btn v-for="(myDate, idx) in dateRange" :key="idx" class="ma-2"  outlined  color="indigo" @click="filterByDate(myDate)">  
              {{myDate.toLocaleDateString("en-US")}} - ({{markIfThisDateHaveItems(myDate)}} - {{markIfThisDateHaveItemsCommison(myDate) + markIfThisDateHaveItemsAmount(myDate)}})
        </v-btn>
      </div>
  </v-row>
  <v-container> 
      <Row v-show="!showTable" :ordersList= 'ordersList' />
      
      <Item v-show="showTable" v-for="item in ordersList" :key="item.id" :item=item :buttons='haveButtons' :comission= 'haveCommission' />

  </v-container>
  <!-- <new-item/>
  <new-item/> -->
  <hr/>
  <div class="text-center">
     <v-btn
        v-if="isItAdmin"
        class="mx-2"
        fab
        dark
        small
         color="teal"
        
        @click.stop="clearDriverOrders(3)"
        >  
        <v-icon dark>
        mdi-minus
      </v-icon>
      </v-btn>
        <v-btn
        v-if="isItAdmin"
          class="mx-2"
        fab
        dark
        small
       color="pink"
        @click="clearDriverOrders(4)"
        >  <v-icon dark>
        mdi-minus
      </v-icon>
      </v-btn>
      
      <Confirm v-on:yesOrNo="inputAction" v-model="showScheduleForm"/>
  </div>
     
</div>
  
</template>

<script>
import Item from '../components/Item'
import Row from '../components/Row'
// import NewItem from '../components/NewItem'
import Confirm from '../components/Confirm'

import {mapGetters, mapState, mapActions} from 'vuex'



export default {
  components: {
      Item,
      Confirm,
      Row

      // NewItem

    },
   
    data () {
      return {
        // selectedStatus: '',
         selection: 0,
          alignments: [
        'start',
        'center',
        'end',
      ],
         showScheduleForm: false,
         status: null,
         showTable: true,
         showaysButtons: false,
         dateFilter: null
      }
    
    },
   computed: {
        ...mapGetters(['paidAmount', 'readyCounts', 'paidAmountWithCommission', 'paidCounts', 'newCounts', 'rejectCounts', 'pendingCounts', 'paidReadyAmount', 'paidReadyAmountWithCommission', 'isThatDateHaveItems', 'isThatDateHaveItemsAmount', 'isThatDateHaveItemsCommision', 'rejectAndReadyCounts']),
        ...mapState(['userType', 'dukandarList', 'loadDukandarForAdmin', 'filteredOrderList', 'stopUsingFilterdOrderListForDateButtons']),
        ordersList() {
          //console.log('selected start');
           
          if (this.selection == 0) {
              //console.log('I am 0');
              var t = this.$store.state.ordersList.filter(x=> x.status <=2 && (!x.driverClearnce || x.driverClearnce == null))
              this.updateFilteredorderList(t)
              this.updateCopyOfFiteredOrders()
              return t
          }
           
          else if (this.selection == 6) {
             
            var dd=   this.$store.state.ordersList.filter(x=> x.driverClearnce)
            //console.log('i am 6');
            this.updateFilteredorderList(dd)
            this.updateCopyOfFiteredOrders()
            return dd
          } else if (this.selection == 44) {
              
            var ee=   this.$store.state.ordersList.filter(x=> x.status == 4 && x.driverClearnce)
            //console.log('i am 6');
            this.updateFilteredorderList(ee)
            this.updateCopyOfFiteredOrders()
             return ee
          }
             
          else {
           
              if (this.showaysButtons) {
                 
                if (this.dateFilter != null) {
                 // console.log('i am datefilter full');
                  var ss= this.$store.state.ordersList.filter(x=> x.status == this.selection && (!x.driverClearnce || x.driverClearnce == null) && new Date(x.deliveryDat).toDateString() == new Date(this.dateFilter).toDateString())
                  this.updateFilteredorderList(ss)
                  // this.updateCopyOfFiteredOrders()
                  return ss
                }  else {
                  //console.log('datefilter is null');
                  // this.updateCopyOfFiteredOrders()
                }
                var ssss= this.$store.state.ordersList.filter(x=> x.status == this.selection && (!x.driverClearnce || x.driverClearnce == null))
                this.updateFilteredorderList(ssss)
                return ssss
              }
             else {
                var rr= this.$store.state.ordersList.filter(x=> x.status == this.selection && (!x.driverClearnce || x.driverClearnce == null))
                this.updateFilteredorderList(rr)
                this.updateCopyOfFiteredOrders()
                return rr
             } 
          } 
        },
       
        sumAnount() {
          if (this.userType == 'dukandar') 
            return this.paidAmount
          else
           return this.paidAmountWithCommission
        },
        haveButtons() {
         return this.userType == 'driver'? true : false
        },
        haveCommission () {
          return this.userType != 'dukandar'? true : false
        },
        isItAdmin() {
          return this.userType == 'admin'
        },
        isItAdminOrDukandar() {
          return this.userType == 'admin' || this.userType == 'dukandar'
        },
        isItAdminOrDrivr() {
          return this.userType == 'admin' || this.userType == 'driver'
        },
         isDukandar() { 
          return  this.userType == 'dukandar'
        },
         showReadyAmountsforDukandarAndAdmin() {
          return  this.userType == 'dukandar' || this.loadDukandarForAdmin
        },
        dateRange() {
          var dt=new Date();
           dt.setDate(dt.getDate()-20)
          var daylist= this.getDaysArray(dt,new Date());
         return daylist

        }
    },
   methods: {
     ...mapActions(['clearDriverOrdersAction', 'updateFilteredorderList', 'toggleStopFIlteringForDatebuttons', 'updateCopyOfFiteredOrders']),
      selectMe(selection2) {
        
        this.selection = parseInt(selection2) 
      
      },
       markIfThisDateHaveItems(theDate) {
          return this.isThatDateHaveItems(theDate)
        },
       markIfThisDateHaveItemsCommison(theDate) {
          return this.isThatDateHaveItemsCommision(theDate)
        },
       markIfThisDateHaveItemsAmount(theDate) {
          return this.isThatDateHaveItemsAmount(theDate)
        },
      clearDriverOrders(status) {
        //console.log(status);
        this.showScheduleForm = true
        this.status = status
        // this.clearDriverOrdersAction(status)
      },
      inputAction($value) {
         // console.log( $value);
          if ($value == 'yes')
           this.clearDriverOrdersAction(this.status)
      },
      getDaysArray (start, end) {
        for(var arr=[],dt=new Date(start); dt<=end; dt.setDate(dt.getDate()+1)){
        arr.push(new Date(dt));
          }
          //console.log(arr);
          return arr;
        },
        filterByDate(daten) {
          this.dateFilter = daten
         this.toggleStopFIlteringForDatebuttons()
        }
    },
//     created() {
//     this.$store.dispatch('loadPosts');
//   },
}
</script>

<style>
  @media print
{
.noprint {display:none;}
}

@media screen
{
}
</style>